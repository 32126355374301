import { PRODUCT_ID } from "../constantsKey";
import apiUrls from "./apiUrlsJsonStore";
import { fetchCookieJwtWithRefreshToken } from "./fetchCookieJwt";
const application = PRODUCT_ID;
const convertJSPref = (p) => {
    return {
        id: p.id,
        ownerId: p.owner,
        payload: JSON.parse(p.payload),
        created: new Date(p.createdAt).getTime(),
        updated: new Date(p.editedAt).getTime(),
    };
};
const convertJSPrefMap = (prefs) => {
    const res = Object.keys(prefs).reduce((acc, key) => {
        acc[key] = prefs[key].map(convertJSPref);
        return acc;
    }, {});
    return res;
};
export const delJSPref = async (context, preferenceId) => {
    const { url, method } = apiUrls.delJsonstorePref;
    await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            application,
            context,
            preferenceId,
        },
    });
};
export const addJSPref = async (context, payload) => {
    const { url, method } = apiUrls.addJsonstorePref;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            application,
            context,
            payload: JSON.stringify(payload),
        },
    });
    return convertJSPref(res);
};
export const putJSPref = async (context, preferenceId, payload) => {
    const { url, method } = apiUrls.putJsonstorePref;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            application,
            context,
            preferenceId,
            payload: JSON.stringify(payload),
        },
    });
    return convertJSPref(res);
};
export const getJSPref = async (contexts) => {
    const { url, method } = apiUrls.getJsonstorePref;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(contexts, application),
        method,
    });
    return convertJSPrefMap(res);
};
const checkMngSetsInconsistencies = (items, mngSets) => {
    const ids = new Set(items.map((i) => i.id));
    return mngSets.map((s) => ({
        ...s,
        payload: {
            ...s.payload,
            items: s.payload.items.filter((i) => ids.has(i.id)),
        },
    }));
};
export const getJSPrefMngSets = async (confs) => {
    const jsSets = await getJSPref(confs.map((c) => c[0]));
    return confs.map((c) => checkMngSetsInconsistencies(c[1], jsSets[c[0]]));
};
export const onCreateSet = async (context, setId, setPayload) => {
    const res = await addJSPref(context, setPayload);
    return res;
};
export const onRemoveSet = async (context, setId) => {
    await delJSPref(context, setId);
};
export const onUpdateSet = async (context, setId, setPayload) => {
    const setUpdated = await putJSPref(context, setId, setPayload);
    return setUpdated;
};
export const onSharedUpsert = async (context, setId) => {
    const { url, method } = apiUrls.upsertJsonstoreShared;
    await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            application,
            context,
            preferenceId: setId,
        },
    });
};
export const onSharedRemove = async (context, setId) => {
    const { url, method } = apiUrls.deleteJsonstoreShared;
    await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            application,
            context,
            preferenceId: setId,
        },
    });
};
export const getItemsShared = async (context) => {
    const { url, method } = apiUrls.getJsonstoreShared;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(context, application),
        method,
    });
    return convertJSPrefMap(res)[context];
};
